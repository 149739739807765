import { Button, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { PAGE } from './constants';

import AnimatedHero from '../components/AnimatedHero.component';
import GameLinks from '../components/GameLinks.component';
import Toast from '../components/Toast.component';
import GameController from '../controllers/Game.controller';
import { useSavedGame } from '../hooks/useSavedGame';
import { setIsSavedGameError } from '../redux/slices/game.slice';
import { IStyles, IToastState } from '../types/common';

const styles: IStyles = {
  container: {
    height: '100%',
    mt: 4,
    overflow: 'hidden',
    position: 'relative'
  },
  gameLinks: {
    mb: 2
  },
  heading: {
    color: 'common.white',
    mb: 4,
    textAlign: 'center'
  }
};

const GamePage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { isSavedGameError } = useSavedGame();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toast, setToast] = useState<IToastState>({
    isOpened: false,
    message: '',
    severity: 'error'
  });

  useEffect(() => {
    if (isSavedGameError) {
      dispatch(setIsSavedGameError({
        isSavedGameError: {
          message: t('error.is-saved-game-error'),
          status: true
        }
      }));
      navigate(PAGE.HOME);
    }
  }, [isSavedGameError]);

  return (
    <>
      <Toast
        isOpened={toast.isOpened}
        message={toast.message}
        severity={toast.severity}
        onClose={() => setToast((state) => ({
          ...state,
          isOpened: false
        }))}
      />
      <Container
        maxWidth="sm"
        sx={styles.container}
      >
        <AnimatedHero />
        <Typography
          sx={styles.heading}
          variant="h1"
        >
          { t('game.heading') }
        </Typography>
        <GameController />
        <GameLinks sx={styles.gameLinks} />
        <Button
          component={Link}
          to={`/${PAGE.DIFFICULTY}`}
          fullWidth
        >
          { t('button.menu') }
        </Button>
      </Container>
    </>
  );
};

export default GamePage;
