import { IQuestion, IRandomQuestion } from '../types/common';

export const getRandomQuestion = (questions: IQuestion[]): IRandomQuestion => {
  const index = Math.floor(Math.random() * questions.length);

  return {
    index,
    question: questions[index]
  };
};
