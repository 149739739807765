import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getSavedGame } from '../api/functions';
import { REQUIRED_POINTS } from '../constants/board';
import { LOCAL_STORAGE } from '../constants/common';
import { PAGE } from '../pages/constants';
import { setCurrentLevel, setCurrentLevelId } from '../redux/slices/game.slice';
import { IAuth } from '../types/common';

export const useSavedGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const { slug } = useParams();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (slug) {
      getSavedGame(slug, search).then(
        (response: any) => {
          const player: IAuth = {
            courseId: response.data.course_id,
            tokens: {
              1: response.data.courses[0],
              2: response.data.courses[1],
              3: response.data.courses[2]
            },
            username: search.replace('?nick=', '')
          };

          localStorage.setItem(LOCAL_STORAGE.AUTH, JSON.stringify(player));

          const { course_id, score, time } = response.data;
          dispatch(setCurrentLevel({
            currentLevelId: course_id,
            points: score >= REQUIRED_POINTS.PRODUCTION ? 0 : score,
            time: score >= REQUIRED_POINTS.PRODUCTION ? 0 : time
          }));
        }
      ).catch(() => {
        setIsError(true);
      });
    } else if (state) {
      dispatch(setCurrentLevelId({
        currentLevelId: Number(state.levelId)
      }));
    } else {
      setIsError(true);
      navigate(PAGE.HOME);
    }
  }, []);

  return {
    isSavedGameError: isError
  };
};
