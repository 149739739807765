import { PaletteOptions, alpha } from '@mui/material';

export const palette = {
  error: '#FF7676',
  gradient: {
    lower: '#9BDCF4',
    upper: '#2A8DF0'
  },
  green: '#4FD89E',
  orange: '#FFB36D',
  primary: {
    light: '#5BAFF2',
    main: '#757AFF'
  },
  red: '#FF7676',
  secondary: '#FFFFFF',
  success: '#8BC727',
  tertiary: '#1D49FF',
  text: '#1C1C1C'
};

const paletteTheme: PaletteOptions = {
  error: {
    main: palette.red
  },
  primary: {
    light: palette.primary.light,
    main: palette.primary.main
  },
  secondary: {
    main: palette.secondary
  },
  success: {
    light: palette.green,
    main: palette.success
  },
  text: {
    primary: palette.text,
    secondary: alpha(palette.text, 0.8)
  },
  warning: {
    main: palette.orange
  }
};

export default paletteTheme;
