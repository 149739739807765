import { CssBaseline, ThemeProvider } from '@mui/material';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { router } from './pages/settings';
import { store } from './redux/settings';
import { theme } from './theme/settings';
import i18n from './translation/settings';

import 'normalize.css';

const Application: React.FunctionComponent = () => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  </I18nextProvider>
);

export default Application;
