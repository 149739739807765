import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import CoFinancingSponsors from './CoFinancingSponsors.component';

import { IStyles } from '../types/common';
import { NestedComponentProps } from '../types/props';

const styles: IStyles = {
  container: {
    backgroundColor: 'secondary.main',
    borderRadius: '10px 10px 0px 0px',
    pb: 3,
    pt: 3
  },
  paragraph: {
    opacity: 0.4
  }
};

const CoFinancing: React.FunctionComponent<NestedComponentProps> = ({
  className
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={className}
      component="footer"
      sx={styles.container}
    >
      <Container
        className="co-financing__container"
        maxWidth="sm"
      >
        <CoFinancingSponsors />
        <Typography
          className="co-financing__paragraph"
          sx={styles.paragraph}
          variant="body2"
        >
          { t('co-financing.paragraph') }
        </Typography>
      </Container>
    </Box>
  );
};

export default CoFinancing;
