import { configureStore } from '@reduxjs/toolkit';

import { gameReducer } from './slices/game.slice';
import { modalReducer } from './slices/modal.slice';

export const store = configureStore({
  reducer: {
    game: gameReducer,
    modal: modalReducer
  }
});
