import { IChoice } from '../types/common';

export const REQUIRED_POINTS = {
  DEV: 2,
  PRODUCTION: 5
};

export const areIdenticalPairs = (array: IChoice[]) => (
  array.every(({ card }) => card === array[0].card)
);

export const getRequiredPoints = (currentLevelId: number) => {
  switch (currentLevelId) {
    case 2:
      return 10;
    case 3:
      return 12;
    default:
      return 8;
  }
};

export const handleLegacyClipboard = (message: string) => {
  const hiddenClipboard = document.createElement('textarea');
  hiddenClipboard.value = message;
  hiddenClipboard.setAttribute('readonly', '');
  hiddenClipboard.style.cssText = `
    left: -9999px;
    position: absolute;
    z-index: -10000;
  `;
  document.body.appendChild(hiddenClipboard);
  hiddenClipboard.select();
  document.execCommand('copy');
  document.body.removeChild(hiddenClipboard);
};
