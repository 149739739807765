import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RXModalProps, RXModalState } from '../../types/redux';

const initialState: RXModalState = {
  closeOption: false,
  content: {
    data: '',
    image: {
      alt: '',
      src: ''
    },
    type: 'default'
  },
  isOpened: false,
  title: ''
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => ({
      ...state,
      isOpened: false
    }),
    setModal: (state, { payload }: PayloadAction<RXModalProps>) => ({
      ...state,
      ...payload
    }),
    openModal: (state) => ({
      ...state,
      isOpened: true
    })
  }
});

export const { closeModal, setModal, openModal } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
