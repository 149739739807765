const getFormat = (time: number) => (time < 10 ? `0${time}` : time);

export const convertToHoursMinutesSeconds = (seconds: number) => {
  const hh = getFormat(Math.floor(seconds / (60 * 60)));
  const mm = getFormat(Math.floor((seconds % (60 * 60)) / 60));
  const ss = getFormat(Math.ceil((seconds % (60 * 60)) % 60));

  return `${hh}:${mm}:${ss}`;
};

export const getRandomEmoji = (index: number) => {
  switch (index) {
    case 0:
      return '⭐';
    case 1:
      return '♻️';
    case 2:
      return '🧠';
    case 3:
      return '🌍';
    case 4:
      return '🌞';
    case 5:
      return '☘️';
    case 6:
      return '🐝';
    case 7:
      return '🐡';
    case 8:
      return '🐢';
    case 9:
      return '😍';
    default:
      return '🎉';
  }
};
