import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HomeButtons from './HomeButtons.component';
import HomeForm from './HomeForm.component';
import Toast from './Toast.component';

import { setIsSavedGameError } from '../redux/slices/game.slice';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  container: {
    [theme.breakpoints.down('sm')]: {
      mb: '60px'
    }
  }
};

const HomeMainContent: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { isSavedGameError } = useSelector((state: RXStates) => state.game);

  const onCloseToast = useCallback(() => {
    dispatch(setIsSavedGameError({
      isSavedGameError: {
        message: '',
        status: false
      }
    }));
  }, []);

  return (
    <>
      <Toast
        isOpened={isSavedGameError.status}
        message={isSavedGameError.message}
        severity="error"
        onClose={onCloseToast}
      />
      <Box
        className="home__main-content"
        component="main"
        sx={styles.container}
      >
        <HomeForm />
        <HomeButtons />
      </Box>
    </>
  );
};

export default HomeMainContent;
