import { Box, useTheme } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';

import logomarkImage from '../assets/logomark.svg';
import { IStyles } from '../types/common';
import { CardProps } from '../types/props';

const styles: IStyles = {
  container: {
    aspectRatio: '1 / 1',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    ':hover': {
      cursor: 'pointer'
    }
  }
};

const Card: React.FunctionComponent<CardProps> = ({
  image, isClicked, onClick
}) => {
  const { palette: { common } } = useTheme();
  const { opacity, transform } = useSpring({
    opacity: isClicked ? 1 : 0,
    transform: `rotateX(${isClicked ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  return (
    <Box
      component="button"
      disabled={isClicked}
      sx={styles.container}
      onClick={onClick}
    >
      <animated.div style={{
        backgroundColor: `${common.white}`,
        backgroundImage: `url(${logomarkImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        opacity: opacity.to((v) => 1 - v),
        transform,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0
      }}
      />
      <animated.div
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity,
          rotateX: '180deg',
          transform,
          position: 'absolute',
          width: '100%',
          height: '100%'
        }}
      />
    </Box>
  );
};

export default Card;
