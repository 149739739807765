import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { PAGE } from './constants';

import CongratulationsButtons from '../components/CongratulationsButtons.component';
import CongratulationsHero from '../components/CongratulationsHero.component';
import Overlay from '../components/Overlay.component';
import { getRequiredPoints } from '../constants/board';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    overflowX: 'hidden'
  },
  heading: {
    color: 'common.white',
    mb: 5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      mt: 5
    }
  }
};

const CongratulationsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { currentLevelId, points } = useSelector(({ game }: RXStates) => game);

  return points === getRequiredPoints(currentLevelId) ? (
    <Overlay className="congratulations">
      <Container
        className="congratulations__container"
        maxWidth="sm"
        sx={styles.container}
      >
        <Typography
          className="congratulations__heading"
          sx={styles.heading}
          variant="h1"
        >
          { t(`difficulty.level.${state.levelId}`) }
        </Typography>
        <CongratulationsHero />
        <CongratulationsButtons />
      </Container>
    </Overlay>
  ) : <Navigate to={`/${PAGE.DIFFICULTY}`} />;
};

export default CongratulationsPage;
