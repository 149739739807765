import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Board from '../components/Board.component';
import { getRequiredPoints } from '../constants/board';
import { useQuestions } from '../hooks/useQuestions';
import { PAGE } from '../pages/constants';
import { updateTime } from '../redux/slices/game.slice';
import { openModal, setModal } from '../redux/slices/modal.slice';
import { RXStates } from '../types/redux';

const REQUIRED_NUMBER_OF_PAIRS = 1;

const GameController: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLevelId, time } = useSelector(({ game }: RXStates) => game);
  const { randomQuestion: { question } } = useQuestions();
  const { pairs, points } = useSelector(({ game }: RXStates) => game);

  // Game time
  useEffect(() => {
    const gameTime = setInterval(() => dispatch(updateTime()), 1000);

    return () => clearInterval(gameTime);
  }, []);

  // Modal settings
  useEffect(() => {
    if (currentLevelId && question && pairs === REQUIRED_NUMBER_OF_PAIRS) {
      dispatch(setModal({
        closeOption: false,
        content: {
          data: question.answers,
          type: 'form'
        },
        title: question.name
      }));
      dispatch(openModal());
    }
  }, [pairs, currentLevelId, question]);

  // Congratulations for the winner
  useEffect(() => {
    if (points === getRequiredPoints(currentLevelId)) {
      navigate(`/${PAGE.CONGRATS}`, {
        state: {
          levelId: currentLevelId,
          time
        }
      });
    }
  }, [points]);

  return (
    <Board />
  );
};

export default GameController;
