import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Image from './Image.component';

import padImage from '../assets/hero/pad.png';
import logotypeImage from '../assets/logotype.svg';
import { mergeSxProps } from '../constants/common';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';

const styles: IStyles = {
  container: {
    mb: '128px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      mt: '46px'
    }
  },
  hero: {
    left: '48%',
    position: 'absolute',
    top: '70px',
    transform: 'translateX(-50%)'
  },
  image: {
    margin: '0 auto'
  }
};

const HomeHeader: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      className="home__header"
      component="header"
      sx={styles.container}
    >
      <Image
        alt={t('alt.logotype')}
        className="home__logotype"
        src={logotypeImage}
        sx={styles.image}
        width="316px"
      />
      <Image
        alt={t('alt.hero-pad')}
        className="home__hero"
        src={padImage}
        sx={mergeSxProps(styles.hero, styles.image)}
        width="140px"
      />
    </Box>
  );
};

export default HomeHeader;
