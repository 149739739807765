import { Components, alpha } from '@mui/material';

import { palette } from './colors';
import { FONT_WEIGHT } from './typography';

const componentsTheme: Components = {
  MuiButton: {
    defaultProps: {
      variant: 'contained'
    },
    styleOverrides: {
      contained: {
        padding: '16px 46px',
        ':active': {
          boxShadow: 'none'
        },
        ':hover': {
          backgroundColor: palette.primary.main
        }
      },
      iconSizeMedium: {
        left: 22,
        position: 'absolute',
        '& > *:first-of-type': {
          fontSize: '44px'
        }
      },
      outlined: {
        borderWidth: 2,
        padding: '14px 46px',
        ':hover': {
          borderWidth: 2
        }
      },
      root: {
        boxShadow: 'none',
        textTransform: 'inherit',
        ':hover': {
          boxShadow: 'none'
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      label: {
        fontSize: '16px',
        fontWeight: FONT_WEIGHT.MEDIUM,
        lineHeight: '25px',
        padding: '8px 16px',
        textTransform: 'uppercase'
      },
      root: {
        borderRadius: 0,
        height: 'inherit'
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        backgroundColor: palette.gradient.lower,
        backgroundImage: `linear-gradient(180deg, ${palette.gradient.upper} 0%, ${palette.gradient.lower} 60%)`,
        backgroundRepeat: 'no-repeat'
      }
    }
  },
  MuiFilledInput: {
    styleOverrides: {
      input: {
        backgroundColor: alpha(palette.tertiary, 0.25),
        color: palette.secondary,
        fontSize: '18px',
        fontWeight: FONT_WEIGHT.MEDIUM,
        lineHeight: '27px',
        padding: 16,
        '::placeholder': {
          color: palette.secondary
        }
      },
      root: {
        borderRadius: 0,
        ':before, :hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: '1px solid transparent'
        }
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.secondary,
        fontSize: '18px',
        fontWeight: FONT_WEIGHT.MEDIUM
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      asterisk: {
        color: palette.error
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .Mui-focused': {
          color: palette.secondary
        }
      }
    }
  }
};

export default componentsTheme;
