import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useBoards from './useBoards';

import { duplicateCards, getRowsByDifficulty, randomize } from '../constants/cards';
import playingCards from '../constants/playingCards';
import { RXStates } from '../types/redux';

const COLUMNS = 4;

const useShuffledCards = () => {
  const { choices, setCurrentChoice, isChecking } = useBoards();
  const { currentLevelId } = useSelector(({ game }: RXStates) => game);

  const cards = useMemo(() => {
    const shuffledCards = playingCards
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    const pile = duplicateCards(shuffledCards);

    return [...pile.splice(0, getRowsByDifficulty(currentLevelId) * COLUMNS)].sort(randomize);
  }, [currentLevelId]);

  return {
    cards,
    choices,
    setCurrentChoice,
    isChecking
  };
};

export default useShuffledCards;
