import { createSlice } from '@reduxjs/toolkit';

import {
  SetCurrentLevelAction, SetCurrentLevelIdAction, SetIsSavedGameErrorAction, SetStatsAction
} from '../../types/action';

const initialState = {
  currentLevelId: 1,
  isIncorrectAnswer: false,
  isSavedGameError: {
    message: '',
    status: false
  },
  pairs: 0,
  points: 0,
  time: 0
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    addPoint: (state) => ({
      ...state,
      points: state.points + 1
    }),
    addPair: (state) => ({
      ...state,
      pairs: state.pairs + 1
    }),
    fetchIncorrectAnswer: (state) => ({
      ...state,
      isIncorrectAnswer: true
    }),
    resetIncorrectAnswer: (state) => ({
      ...state,
      isIncorrectAnswer: false
    }),
    resetPairs: (state) => ({
      ...state,
      pairs: 0
    }),
    resetStats: () => ({
      currentLevelId: 1,
      isIncorrectAnswer: false,
      isSavedGameError: {
        message: '',
        status: false
      },
      pairs: 0,
      points: 0,
      time: 0
    }),
    setCurrentLevel: (state, { payload }: SetCurrentLevelAction) => ({
      ...state,
      currentLevelId: payload.currentLevelId,
      points: payload.points,
      time: payload.time
    }),
    setCurrentLevelId: (state, { payload }: SetCurrentLevelIdAction) => ({
      ...state,
      currentLevelId: payload.currentLevelId
    }),
    setIsSavedGameError: (state, { payload }: SetIsSavedGameErrorAction) => ({
      ...state,
      isSavedGameError: payload.isSavedGameError
    }),
    setStats: (state, { payload }: SetStatsAction) => ({
      ...state,
      points: payload.points,
      time: payload.time
    }),
    updateTime: (state) => ({
      ...state,
      time: state.time + 1
    })
  }
});

export const {
  addPair,
  addPoint,
  fetchIncorrectAnswer,
  resetPairs,
  resetStats,
  resetIncorrectAnswer,
  setCurrentLevel,
  setCurrentLevelId,
  setIsSavedGameError,
  setStats,
  updateTime
} = gameSlice.actions;

export const gameReducer = gameSlice.reducer;
