import { Box } from '@mui/material';

import Image from './Image.component';

import nfosigwImage from '../assets/sponsors/nfosigw.svg';
import wfosigwwsImage from '../assets/sponsors/wfosigwws.svg';
import i18n from '../translation/settings';
import { IImage, IStyles } from '../types/common';

const data: IImage[] = [
  {
    alt: i18n.t('alt.wfosigwws'),
    src: wfosigwwsImage
  },
  {
    alt: i18n.t('alt.nfosigw'),
    src: nfosigwImage
  }
];

const styles: IStyles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 2,
    mb: 2
  }
};

const CoFinancingSponsors: React.FunctionComponent = () => (
  <Box sx={styles.container}>
    {
      data.map(({ alt, src }, index) => (
        <Image
          alt={alt}
          key={`CoFinancingSponsors-sponsor-item-${index}`}
          src={src}
          width="100%"
        />
      ))
    }
  </Box>
);

export default CoFinancingSponsors;
