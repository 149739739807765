import { Controller } from 'react-hook-form';

import Field from './Field.component';

import { FormControllerProps } from '../types/props';

const FormController: React.FunctionComponent<FormControllerProps> = ({
  control, helperText, label, name, rules, type = 'text'
}) => (
  <Controller
    control={control}
    defaultValue=""
    name={name}
    render={
    ({ field, fieldState: { error } }) => (
      <Field
        error={error}
        field={field}
        helperText={helperText}
        label={label}
        name={name}
        required={Boolean(rules?.required)}
        type={type}
      />
    )
  }
    rules={rules}
  />
);

export default FormController;
