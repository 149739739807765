import { Box } from '@mui/material';
import { useCallback } from 'react';

import Card from './Card.component';

import useShuffledCards from '../hooks/useShuffledCards';
import { IStyles } from '../types/common';

const styles: IStyles = {
  container: {
    display: 'grid',
    gap: 2,
    gridTemplateColumns: 'repeat(4, 1fr)',
    mb: 5
  }
};

const Board: React.FunctionComponent = () => {
  const {
    cards, choices, setCurrentChoice, isChecking
  } = useShuffledCards();

  const handleClickFlippedCard = useCallback((image: string, id: number) => () => {
    if (isChecking) {
      return;
    }

    setCurrentChoice(
      (state) => [...state, { card: image, id }]
    );
  }, [isChecking]);

  return (
    <Box sx={styles.container}>
      {
        cards.map(
          (image, id) => (
            <Card
              image={image}
              isClicked={choices.some((choice) => choice.id === id)}
              key={`Board-card-item-${id}`}
              onClick={handleClickFlippedCard(image, id)}
            />
          )
        )
      }
    </Box>
  );
};

export default Board;
