import { useTranslation } from 'react-i18next';

import Dialogue from './Dialogue.component';
import Image from './Image.component';

import heroImage from '../assets/animations/stars.gif';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';

const styles: IStyles = {
  dialogue: {
    alignSelf: 'end',
    mt: 6,
    minWidth: '380px',
    [theme.breakpoints.down('sm')]: {
      mt: 16,
      minWidth: '300px',
      '& .dialogue__arrow': {
        left: '65%',
        top: '-50%',
        transform: 'rotate(90deg)'
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& .dialogue__arrow': {
        left: '40%',
        top: '70%',
        transform: 'rotate(180deg)'
      }
    },
    '@media (max-width: 320px)': {
      display: 'none'
    }
  },
  image: {
    position: 'absolute',
    zIndex: -10,
    bottom: '0%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '360px',
      position: 'relative',
      transform: 'translateY(-10%)'
    },
    '@media (max-width: 320px)': {
      display: 'none'
    }
  }
};

const DifficultyHero: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Dialogue
        className="difficulty__dialogue"
        sx={styles.dialogue}
        hasArrow
      >
        { t('difficulty.paragraph') }
      </Dialogue>
      <Image
        alt="Zielonek"
        className="difficulty__image"
        src={heroImage}
        sx={styles.image}
        width="300px"
      />
    </>
  );
};

export default DifficultyHero;
