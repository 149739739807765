import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DifficultyButtons from '../components/DifficultyButtons.component';
import DifficultyHero from '../components/DifficultyHero.component';
import Overlay from '../components/Overlay.component';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';

const styles: IStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      maxHeight: '-webkit-fill-available',
      py: 2
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center'
    }
  },
  heading: {
    color: 'common.white',
    mb: 1,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '46px'
    }
  }
};

const DifficultyPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Overlay className="difficulty">
      <Container
        className="difficulty__container"
        maxWidth="sm"
        sx={styles.container}
      >
        <div>
          <Typography
            className="difficulty__heading"
            sx={styles.heading}
            variant="h1"
          >
            { t('difficulty.heading') }
          </Typography>
          <DifficultyButtons />
        </div>
        <DifficultyHero />
      </Container>
    </Overlay>
  );
};

export default DifficultyPage;
