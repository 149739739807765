import { DIFFICULTY } from './common';

export const duplicateCards = (cards: string[]) => (
  cards.concat(cards).sort()
);

export const getRowsByDifficulty = (levelId: number): number => {
  switch (levelId) {
    case DIFFICULTY.HARD:
      return 6;
    case DIFFICULTY.NORMAL:
      return 5;
    default:
      return 4;
  }
};

export const randomize = () => 0.5 - Math.random();
