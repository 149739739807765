import { Box, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import CircleImage from './CircleImage.component';

import { closeModal } from '../redux/slices/modal.slice';
import CloseIcon from '../svgs/CloseIcon';
import { IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  box: {
    backgroundColor: 'primary.light'
  },
  button: {
    alignItems: 'start',
    height: 'fit-content',
    justifyContent: 'end',
    p: 0
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    mb: 2
  },
  icon: {
    color: 'primary.main',
    fontSize: '50px'
  },
  image: {
    mt: 3
  }
};

const ModalHeader: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    closeOption, content: {
      image
    }
  } = useSelector((state: RXStates) => state.modal);

  return (image || closeOption) ? (
    <Box sx={styles.container}>
      {
        image && (
          <CircleImage
            alt={image.alt}
            box={styles.box}
            src={image.src}
            sx={styles.image}
            width="160px"
          />
        )
      }
      {
        closeOption && (
          <IconButton
            sx={styles.button}
            onClick={() => dispatch(closeModal())}
          >
            <CloseIcon sx={styles.icon} />
          </IconButton>
        )
      }
    </Box>
  ) : null;
};

export default ModalHeader;
