import { AxiosResponse } from 'axios';

import { SERVICE_KEY } from './constants';
import { API } from './settings';

import { APICourse, APIUser } from '../types/api';
import { ICourse } from '../types/common';

export const getCourse = async (courseId: number): Promise<AxiosResponse<ICourse>> => (
  API.get<ICourse>(`${SERVICE_KEY.COURSES}/${courseId}`, {
    transformResponse: [
      (response) => {
        const { data }: APICourse = JSON.parse(response);
        return data;
      }
    ]
  })
);

export const getSavedGame = async (slug: string, searchParams: string): Promise<AxiosResponse> => (
  API.get(`getScore/${slug}${searchParams}`)
);

export const getRankingItems = async (): Promise<AxiosResponse<APIUser[]>> => (
  API.get<APIUser[]>('scoreTable', {
    transformResponse: [
      (response) => {
        const { data }: AxiosResponse<APIUser[]> = JSON.parse(response);

        return data;
      }
    ]
  })
);

export const initializeUser = async (
  nickname: string
): Promise<AxiosResponse> => (
  API.post<APIUser>(SERVICE_KEY.INITIALIZE_USER, {
    nick: nickname,
    score: 0,
    time: 0
  })
);

export const saveGame = async (nick: string, score: number, time: number, token: string) => (
  API.post<APIUser>(`${SERVICE_KEY.SCORE}/${token}`, {
    nick,
    score,
    time
  })
);
