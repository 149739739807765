import {
  FormGroup, Typography
} from '@mui/material';
import {
  useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import DecisionBlock from './DecisionBlock.component';
import DecisionInformation from './DecisionInformation.component';

import { saveGame } from '../api/functions';
import { getAuth, getTokenById } from '../constants/common';
import { addPoint, fetchIncorrectAnswer, resetPairs } from '../redux/slices/game.slice';
import { closeModal } from '../redux/slices/modal.slice';
import { DecisionProps } from '../types/props';
import { RXStates } from '../types/redux';

const CLOSE_MODAL_TIMER_DELAY = 3000;

const Decision: React.FunctionComponent<DecisionProps> = ({
  answers
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { slug } = useParams();
  const { search } = useLocation();
  const { points, time } = useSelector(({ game }: RXStates) => game);
  const [isActivated, setIsActivated] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState({
    target: '',
    isCorrect: false
  });

  useEffect(() => {
    if (isActivated) {
      if (selectedAnswer.isCorrect) {
        dispatch(addPoint());
      } else {
        dispatch(fetchIncorrectAnswer());
      }
      dispatch(resetPairs());
    }
  }, [isActivated, selectedAnswer]);

  useEffect(() => {
    if (points > 0) {
      const token = slug ?? getTokenById(state.levelId);
      const nick = search ? search.replace('?nick=', '') : getAuth().username;

      saveGame(nick, points, time, token);
    }
  }, [points]);

  useEffect(() => {
    const closeModalTimer = setTimeout(() => {
      if (isActivated) {
        dispatch(closeModal());
      }
    }, CLOSE_MODAL_TIMER_DELAY);

    return () => clearTimeout(closeModalTimer);
  }, [isActivated]);

  const handleCheckbox = (target: string, isCorrect: boolean) => {
    setSelectedAnswer({ target, isCorrect });
    setIsActivated(true);
  };

  return answers.length > 0 ? (
    <>
      <DecisionInformation {...selectedAnswer} />
      <FormGroup>
        {
          answers.map(
            ({ name, isCorrect }, index) => (
              <DecisionBlock
                checked={selectedAnswer.target === name}
                disabled={isActivated}
                isCorrect={isCorrect}
                key={`Decision-answer-item-${index}`}
                name={name}
                selectCheckbox={handleCheckbox}
              />
            )
          )
        }
      </FormGroup>
    </>
  ) : <Typography color="error.main">{ t('decision.error') }</Typography>;
};

export default Decision;
