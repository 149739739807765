import { Button, List, ListItem } from '@mui/material';
import React from 'react';

import { mergeSxProps } from '../constants/common';
import { IStyles } from '../types/common';
import { ButtonsProps } from '../types/props';

const styles: IStyles = {
  list: {
    p: 0
  },
  listItem: {
    pl: 0,
    pr: 0,
    ':first-of-type': {
      pt: 0
    },
    ':last-of-type': {
      pb: 0
    }
  }
};

const Buttons: React.FunctionComponent<ButtonsProps> = ({
  className, data, sx
}) => (data.length > 0 ? (
  <List
    className={className}
    sx={mergeSxProps(styles.list, sx)}
  >
    {
      data.map((props, index) => (
        <ListItem
          className="buttons__list-item"
          key={`Buttons-list-item-${index}`}
          sx={styles.listItem}
        >
          <Button
            className={`buttons__button buttons__button--${index}`}
            fullWidth
            {...props}
          />
        </ListItem>
      ))
    }
  </List>
) : null);

export default React.memo(Buttons);
