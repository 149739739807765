import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DecisionInformationProps } from '../types/props';

const DecisionInformation: React.FunctionComponent<DecisionInformationProps> = ({
  isCorrect, target
}) => {
  const { t } = useTranslation();

  return target ? (
    <Alert
      severity={ isCorrect ? 'success' : 'error'}
      sx={{
        fontSize: '14px',
        mb: 1
      }}
    >
      { isCorrect ? t('decision.success') : t('decision.fail') }
    </Alert>
  ) : null;
};

export default DecisionInformation;
