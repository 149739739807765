import { Alert, Snackbar } from '@mui/material';

import { IStyles } from '../types/common';
import { ToastProps } from '../types/props';

const styles: IStyles = {
  alert: {
    fontSize: '16px',
    lineHeight: '25px',
    '& .MuiAlert-message': {
      alignSelf: 'center',
      p: 0
    }
  }
};

const Toast: React.FunctionComponent<ToastProps> = ({
  isOpened, message, onClose, severity
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={3000}
    open={isOpened}
    onClose={onClose}
  >
    <Alert
      severity={severity}
      sx={styles.alert}
      onClose={onClose}
    >
      { message }
    </Alert>
  </Snackbar>
);

export default Toast;
