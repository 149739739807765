import card1 from '../assets/cards/card-1.jpg';
import card10 from '../assets/cards/card-10.jpg';
import card11 from '../assets/cards/card-11.jpg';
import card12 from '../assets/cards/card-12.jpg';
import card13 from '../assets/cards/card-13.jpg';
import card14 from '../assets/cards/card-14.jpg';
import card15 from '../assets/cards/card-15.jpg';
import card16 from '../assets/cards/card-16.jpg';
import card17 from '../assets/cards/card-17.jpg';
import card18 from '../assets/cards/card-18.jpg';
import card19 from '../assets/cards/card-19.jpg';
import card2 from '../assets/cards/card-2.jpg';
import card20 from '../assets/cards/card-20.jpg';
import card3 from '../assets/cards/card-3.jpg';
import card4 from '../assets/cards/card-4.jpg';
import card5 from '../assets/cards/card-5.jpg';
import card6 from '../assets/cards/card-6.jpg';
import card7 from '../assets/cards/card-7.jpg';
import card8 from '../assets/cards/card-8.jpg';
import card9 from '../assets/cards/card-9.jpg';

const playingCards = [
  card1, card2, card3, card4,
  card5, card6, card7, card8,
  card9, card10, card11, card12,
  card13, card14, card15, card16,
  card17, card18, card19, card20
];

export default playingCards;
