import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Buttons from './Buttons.component';

import { PAGE } from '../pages/constants';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';

const styles: IStyles = {
  buttons: {
    [theme.breakpoints.down('sm')]: {
      mb: 3
    }
  }

};

const CongratulationsButtons: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkButton = useCallback((link: string) => () => {
    navigate(link);
  }, []);

  return (
    <Buttons
      className="congratulations__buttons"
      data={[
        {
          children: t('button.play-again'),
          onClick: handleLinkButton(`/${PAGE.DIFFICULTY}`)
        },
        {
          children: t('button.return-home'),
          onClick: handleLinkButton(PAGE.HOME),
          variant: 'outlined'
        }
      ]}
      sx={styles.buttons}
    />
  );
};

export default CongratulationsButtons;
