import { Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormController from './FormController.component';

import { FormProps } from '../types/props';

const Form: React.FunctionComponent<FormProps> = ({
  button: {
    label, onSubmit
  },
  fields,
  sx
}) => {
  const { control, handleSubmit } = useForm();

  return (
    <Box
      component="form"
      sx={sx}
      onSubmit={handleSubmit(onSubmit)}
    >
      {
        fields.map(
          (props, index) => (
            <FormController
              control={control}
              key={`Form-field-item-${index}`}
              {...props}
            />
          )
        )
      }
      <Button
        type="submit"
        fullWidth
      >
        { label }
      </Button>
    </Box>
  );
};

export default Form;
