import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

import Image from './Image.component';

import variant1 from '../assets/animations/gameplay.gif';

const IMAGES = [variant1];

const MINUTES = 3;
enum DELAY {
  TOGGLE_OFF = 4000,
  TOGGLE_ON = MINUTES * 60000
}

const AnimatedHero: React.FunctionComponent = () => {
  const [currentImage, setCurrentImage] = useState(IMAGES[0]);
  const [isToggled, setIsToggled] = useState(false);
  const springStyles = useSpring({
    transform: `translateY(${isToggled ? '5%' : '100%'})`,
    config: {
      mass: 8,
      tension: 300,
      friction: 70
    }
  });

  useEffect(() => {
    const toggleOnTimer = setInterval(() => {
      setIsToggled(true);
    }, DELAY.TOGGLE_ON);

    return () => clearInterval(toggleOnTimer);
  }, [isToggled]);

  useEffect(() => {
    const toggleOffTimer = setTimeout(() => {
      setIsToggled(
        (state) => (state ? false : state)
      );

      if (!isToggled) {
        const randomIndex = Math.floor(Math.random() * IMAGES.length);
        setCurrentImage(IMAGES[randomIndex]);
      }
    }, DELAY.TOGGLE_OFF);

    return () => clearTimeout(toggleOffTimer);
  }, [isToggled]);

  return (
    <animated.div style={{
      ...springStyles,
      bottom: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: '5%',
      zIndex: 20
    }}
    >
      <Image
        alt=""
        src={currentImage}
        width="370px"
      />
    </animated.div>
  );
};

export default AnimatedHero;
