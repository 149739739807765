import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getCourse } from '../api/functions';
import { getRandomQuestion } from '../constants/questions';
import { IQuestion, IRandomQuestion } from '../types/common';
import { RXStates } from '../types/redux';

export const useQuestions = () => {
  const { state } = useLocation();

  const { currentLevelId } = useSelector(({ game }: RXStates) => game);
  const { isOpened } = useSelector(({ modal }: RXStates) => modal);

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [randomQuestion, setRandomQuestion] = useState<IRandomQuestion>({
    index: -1,
    question: {
      answers: [],
      id: -1,
      name: ''
    }
  });

  // Get all questions
  useEffect(() => {
    const fetchQuestions = async () => {
      const { data } = await getCourse(state ? Number(state.levelId) : currentLevelId);
      setQuestions(data.questions);
    };

    fetchQuestions();
  }, [currentLevelId]);

  // Get the random question
  useEffect(() => {
    if (questions.length > 0) {
      setRandomQuestion({ ...getRandomQuestion(questions) });
    }
  }, [isOpened, questions]);

  return {
    randomQuestion,
    questions
  };
};
