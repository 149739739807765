import { SvgIcon } from '@mui/material';

import { OptionalSxProps } from '../types/props';

const WinnerIcon: React.FunctionComponent<OptionalSxProps> = (props) => (
  <SvgIcon
    height="43.684"
    viewBox="0 0 45.634 43.684"
    width="45.634"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0 -10.939)">
      <path
        d="M352.89,321.039h-7.9a3.656,3.656,0,0,0-3.656,3.656v12.389h15.211V324.695A3.656,3.656,0,0,0,352.89,321.039Z"
        fill="#fe646f"
        transform="translate(-310.911 -282.461)"
      />
      <path
        d="M297.335,321.043a3.655,3.655,0,0,0-3.655,3.655v12.389h-8.648L290.05,324.7a3.655,3.655,0,0,1,3.655-3.655Z"
        fill="#fd4755"
        transform="translate(-259.627 -282.465)"
      />
      <g transform="translate(35.35 40.8)">
        <path
          d="M399.266,355.219a2.654,2.654,0,0,1-2.655-2.655.689.689,0,1,1,1.377,0,1.278,1.278,0,1,0,1.278-1.278.689.689,0,1,1,0-1.377,1.278,1.278,0,1,0-1.254-1.526.689.689,0,1,1-1.352-.265,2.655,2.655,0,1,1,4.388,2.48,2.654,2.654,0,0,1-1.782,4.622Z"
          fill="#3e5959"
          transform="translate(-396.611 -345.975)"
        />
      </g>
      <path
        d="M11.556,277.922h-7.9A3.656,3.656,0,0,0,0,281.578V297.81H17.528l-2.317-16.232A3.656,3.656,0,0,0,11.556,277.922Z"
        fill="#deecf1"
        transform="translate(0 -243.187)"
      />
      <path
        d="M7.285,277.919a3.656,3.656,0,0,0-3.655,3.656v16.232H0V281.575a3.656,3.656,0,0,1,3.655-3.656Z"
        fill="#cbe5e8"
        transform="translate(0 -243.184)"
      />
      <path
        d="M182.223,214.091h-7.9a3.656,3.656,0,0,0-3.656,3.656v21.922h15.211V217.747A3.656,3.656,0,0,0,182.223,214.091Z"
        fill="#ffe177"
        transform="translate(-155.456 -185.045)"
      />
      <path
        d="M177.952,214.084a3.657,3.657,0,0,0-3.656,3.656v21.921h-3.63V217.74a3.657,3.657,0,0,1,3.656-3.656Z"
        fill="#fec165"
        transform="translate(-155.455 -185.039)"
      />
      <g transform="translate(21.307 32.031)">
        <path
          d="M241.388,256.65a.689.689,0,0,1-.689-.689v-7h-.954a.689.689,0,1,1,0-1.377h1.643a.689.689,0,0,1,.689.689v7.686A.689.689,0,0,1,241.388,256.65Z"
          fill="#3e5959"
          transform="translate(-239.057 -247.587)"
        />
      </g>
      <g transform="translate(4.806 37.227)">
        <path
          d="M56.373,315.1a6.823,6.823,0,0,1-1.677-.067.726.726,0,0,1-.473-.478c-.121-.393.054-.623.523-1.236.391-.512,1.119-1.465,2.339-3.208a4.409,4.409,0,0,0,.7-1.4l.027-.212a1.278,1.278,0,0,0-2.531-.209.689.689,0,1,1-1.352-.265,2.655,2.655,0,0,1,5.261.514.7.7,0,0,1-.006.087l-.038.3a.656.656,0,0,1-.012.068,5.661,5.661,0,0,1-.924,1.908c-.952,1.36-1.609,2.247-2.042,2.82.74,0,1.726-.006,2.65-.018h.009a.689.689,0,0,1,.008,1.377C57.749,315.1,56.955,315.1,56.373,315.1Z"
          fill="#3e5959"
          transform="translate(-53.919 -305.889)"
        />
      </g>
      <path
        d="M191.175,29.031l.833,1.688a1.508,1.508,0,0,0,1.135.825l1.863.271a1.508,1.508,0,0,1,.835,2.571L194.494,35.7a1.508,1.508,0,0,0-.434,1.334l.318,1.855a1.508,1.508,0,0,1-2.187,1.589l-1.666-.876a1.507,1.507,0,0,0-1.4,0l-1.666.876a1.508,1.508,0,0,1-2.187-1.589l.318-1.855a1.508,1.508,0,0,0-.434-1.334L183.8,34.386a1.508,1.508,0,0,1,.835-2.571l1.863-.271a1.508,1.508,0,0,0,1.135-.825l.833-1.688a1.508,1.508,0,0,1,2.7,0Z"
        fill="#ffe177"
        transform="translate(-167.006 -15.714)"
      />
      <path
        d="M190.953,28.7a1.67,1.67,0,0,0-.267.391l-.894,1.812a1.619,1.619,0,0,1-1.218.886l-2,.29a1.618,1.618,0,0,0-.9,2.76l1.447,1.41a1.617,1.617,0,0,1,.466,1.432l-.342,1.992a1.6,1.6,0,0,0,.084.859,1.506,1.506,0,0,1-2.065-1.645l.318-1.856a1.507,1.507,0,0,0-.433-1.334L183.8,34.383a1.508,1.508,0,0,1,.836-2.572l1.863-.271a1.506,1.506,0,0,0,1.135-.825l.833-1.688A1.508,1.508,0,0,1,190.953,28.7Z"
        fill="#fec165"
        transform="translate(-167.003 -15.711)"
      />
      <g transform="translate(5.388 10.939)">
        <g transform="translate(27.648 0)">
          <path
            d="M370.868,14.627l.114.2a1.535,1.535,0,0,0,2,.611l2.229-1.091a1.535,1.535,0,0,0,.655-2.146l-.284-.492A1.535,1.535,0,0,0,373.4,11.2l-2.059,1.385A1.534,1.534,0,0,0,370.868,14.627Z"
            fill="#ffe177"
            transform="translate(-370.662 -10.94)"
          />
          <path
            d="M389.326,97.855v.229a1.535,1.535,0,0,0,1.429,1.531l2.476.17a1.535,1.535,0,0,0,1.64-1.531v-.568a1.535,1.535,0,0,0-1.64-1.531l-2.476.17A1.535,1.535,0,0,0,389.326,97.855Z"
            fill="#ffe177"
            transform="translate(-387.663 -88.556)"
          />
          <path
            d="M371.081,170.774l-.114.2a1.535,1.535,0,0,0,.472,2.041L373.5,174.4a1.535,1.535,0,0,0,2.186-.506l.284-.492a1.535,1.535,0,0,0-.654-2.146l-2.229-1.091A1.534,1.534,0,0,0,371.081,170.774Z"
            fill="#ffe177"
            transform="translate(-370.752 -155.829)"
          />
        </g>
        <path
          d="M85.808,14.626l-.114.2a1.535,1.535,0,0,1-2,.611L81.46,14.345a1.535,1.535,0,0,1-.655-2.146l.284-.492a1.535,1.535,0,0,1,2.186-.506l2.06,1.385A1.535,1.535,0,0,1,85.808,14.626Z"
          fill="#ffe177"
          transform="translate(-78.805 -10.939)"
        />
        <path
          d="M66,97.855v.229a1.535,1.535,0,0,1-1.429,1.531l-2.476.17a1.535,1.535,0,0,1-1.64-1.531v-.568a1.535,1.535,0,0,1,1.64-1.531l2.476.17A1.535,1.535,0,0,1,66,97.855Z"
          fill="#ffe177"
          transform="translate(-60.457 -88.556)"
        />
        <path
          d="M85.6,170.774l.114.2a1.535,1.535,0,0,1-.472,2.041L83.18,174.4a1.535,1.535,0,0,1-2.186-.506l-.284-.492a1.535,1.535,0,0,1,.654-2.146l2.229-1.091A1.534,1.534,0,0,1,85.6,170.774Z"
          fill="#ffe177"
          transform="translate(-78.717 -155.829)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default WinnerIcon;
