import { Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { mergeSxProps } from '../constants/common';
import { IStyles } from '../types/common';
import { DialogueProps } from '../types/props';

const styles: IStyles = {
  chip: {
    color: 'common.white',
    left: 16,
    position: 'absolute',
    top: 0,
    transform: 'translateY(-50%)'
  },
  container: {
    backgroundColor: 'common.white',
    borderRadius: 1,
    pb: 2,
    pt: 4,
    px: 2,
    mt: '20px',
    position: 'relative'
  },
  content: {
    position: 'relative',
    zIndex: 10
  },
  triangle: {
    borderBottom: '70px solid',
    borderColor: 'common.white',
    borderLeft: '70px solid transparent',
    height: 0,
    left: '40%',
    position: 'absolute',
    top: -60,
    width: 0
  }
};

const Dialogue: React.FunctionComponent<DialogueProps> = ({
  children, className, hasArrow, sx
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={className}
      sx={mergeSxProps(styles.container, sx)}
    >
      {
        hasArrow && (
          <Box
            className="dialogue__arrow"
            sx={styles.triangle}
          />
        )
      }
      <Chip
        className="dialogue__name"
        color="success"
        label={t('common.hero-name')}
        sx={styles.chip}
      />
      <Typography
        className="dialogue__content"
        sx={styles.content}
      >
        { children }
      </Typography>
    </Box>
  );
};

export default Dialogue;
