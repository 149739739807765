import { createTheme } from '@mui/material';

import paletteTheme from './colors';
import componentsTheme from './components';
import typographyTheme from './typography';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200
    }
  },
  components: componentsTheme,
  palette: paletteTheme,
  shape: {
    borderRadius: 10
  },
  typography: typographyTheme
});
