import {
  Dialog, DialogContent, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Decision from './Decision.component';
import ModalTitleBar from './ModalTitleBar.component';

import { closeModal } from '../redux/slices/modal.slice';
import { theme } from '../theme/settings';
import { IAnswer, IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  container: {
    '& .MuiDialog-paper': {
      boxShadow: 'none',
      [theme.breakpoints.up('sm')]: {
        borderRadius: 1,
        height: 'fit-content',
        margin: 2,
        maxWidth: theme.breakpoints.values.md,
        position: 'fixed',
        top: '20%',
        width: 'calc(100% - 32px)'
      }
    }
  },
  content: {
    padding: 2
  }
};

const Modal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    closeOption, content: {
      data, type
    }, isOpened
  } = useSelector((state: RXStates) => state.modal);

  return (
    <Dialog
      open={isOpened}
      sx={styles.container}
      fullWidth
      onClose={closeOption ? () => dispatch(closeModal()) : undefined}
    >
      <ModalTitleBar />
      <DialogContent sx={styles.content}>
        {
          type === 'default'
            ? <Typography sx={{ whiteSpace: 'pre-line' }}>{ data as string }</Typography>
            : <Decision answers={data as IAnswer[]} />
        }
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
