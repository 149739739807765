import { Box } from '@mui/material';

import Image from './Image.component';

import { mergeSxProps } from '../constants/common';
import { IStyles } from '../types/common';
import { CircleImageProps } from '../types/props';

const styles: IStyles = {
  circle: {
    borderRadius: '50%',
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  container: {
    position: 'relative',
    width: 'fit-content'
  }
};

const CircleImage: React.FunctionComponent<CircleImageProps> = ({
  alt, box, src, sx, width
}) => (
  <Box sx={mergeSxProps(styles.container, sx)}>
    <Image
      alt={alt}
      src={src}
      sx={{
        position: 'relative',
        zIndex: 10
      }}
      width={width}
    />
    <Box sx={mergeSxProps(styles.circle, box)} />
  </Box>
);

export default CircleImage;
