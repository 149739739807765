import { ListItem, Typography } from '@mui/material';

import { convertToHoursMinutesSeconds, getRandomEmoji } from '../constants/ranking';
import { IStyles } from '../types/common';
import { RankingItemProps } from '../types/props';

const styles: IStyles = {
  emoji: {
    fontSize: '34px'
  },
  heading: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px'
  },
  list: {
    backgroundColor: 'common.white',
    borderRadius: 1,
    justifyContent: 'space-between',
    mb: '2px',
    padding: '12px 16px',
    ':last-of-type': {
      mb: 0
    }
  }
};

const RankingItem: React.FunctionComponent<RankingItemProps> = ({
  index, nick, score, time
}) => (
  <ListItem
    alignItems="center"
    className="ranking__item"
    sx={styles.list}
  >
    <Typography
      className="ranking__username"
      sx={{ ...styles.heading, color: (index < 3) ? 'success.main' : undefined }}
    >
      { `${index + 1}. ${nick} - ${convertToHoursMinutesSeconds(time)} - ${score} pkt.` }
    </Typography>
    <Typography
      className="ranking__emoji"
      sx={styles.emoji}
    >
      { getRandomEmoji(index) }
    </Typography>
  </ListItem>
);

export default RankingItem;
