import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { areIdenticalPairs } from '../constants/board';
import { addPair, resetIncorrectAnswer } from '../redux/slices/game.slice';
import { IChoice } from '../types/common';
import { RXStates } from '../types/redux';

const REQUIRED_NUMBER_OF_CARDS = 2;

const useBoards = () => {
  const dispatch = useDispatch();
  const { isIncorrectAnswer } = useSelector(({ game }: RXStates) => game);

  const [isDetectedPair, setIsDetectedPair] = useState(false);
  const [resetChoicesFlag, setResetChoicesFlag] = useState(false);

  const [choices, setChoices] = useState<IChoice[]>([]);
  const [currentChoice, setCurrentChoice] = useState<IChoice[]>([]);
  const [filteredChoices, setFilteredChoices] = useState<IChoice[]>([]);

  const [isChecking, setIsChecking] = useState(false);

  // Get clicked cards
  useEffect(() => {
    if (currentChoice.length) {
      setIsChecking(true);
      setFilteredChoices((state) => (
        [...state, currentChoice[currentChoice.length - 1]]
      ));
    }
  }, [currentChoice]);

  // Compare last items
  useEffect(() => {
    setChoices(filteredChoices);
    if (currentChoice.length !== REQUIRED_NUMBER_OF_CARDS) {
      setIsChecking(false);
      return;
    }

    setCurrentChoice([]);
    if (!areIdenticalPairs(currentChoice.slice(-2))) {
      setResetChoicesFlag(true);
      return;
    }

    dispatch(addPair());
    setIsDetectedPair(true);
    setResetChoicesFlag(true);
    setIsChecking(false);
  }, [filteredChoices]);

  // Reset cards and change flags
  useEffect(() => {
    const resetCardsTimer = setTimeout(() => {
      if (resetChoicesFlag || isIncorrectAnswer) {
        /*
          If the user clicked on invalid pairs,
          delete the last items of the array.
        */
        setFilteredChoices((previousChoices) => (
          isDetectedPair
            ? previousChoices
            : previousChoices.slice(0, -2)
        ));
        setResetChoicesFlag(false);
        setIsDetectedPair(false);

        setIsChecking(false);

        if (isIncorrectAnswer) {
          dispatch(resetIncorrectAnswer());
        }
      }
    }, 400);

    return (() => {
      clearTimeout(resetCardsTimer);
    });
  }, [resetChoicesFlag, isIncorrectAnswer]);

  return {
    choices,
    setCurrentChoice,
    isChecking
  };
};

export default useBoards;
