import { DialogTitle, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import ModalHeader from './ModalHeader.component';

import { IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  container: {
    p: 2
  },
  heading: {
    color: 'primary.main'
  }
};

const ModalTitleBar: React.FunctionComponent = () => {
  const {
    content: { type }, title
  } = useSelector((state: RXStates) => state.modal);

  return (
    <DialogTitle
      component="header"
      sx={styles.container}
    >
      <ModalHeader />
      <Typography
        component="h2"
        sx={styles.heading}
        variant={type === 'default' ? 'h1' : 'h6'}
      >
        { title }
      </Typography>
    </DialogTitle>
  );
};

export default ModalTitleBar;
