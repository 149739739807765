import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { handleLegacyClipboard } from '../constants/board';
import { getAuth, getTokenById, mergeSxProps } from '../constants/common';
import { PAGE } from '../pages/constants';
import CopyIcon from '../svgs/CopyIcon';
import { IStyles } from '../types/common';
import { OptionalSxProps } from '../types/props';

const styles: IStyles = {
  button: {
    backgroundColor: 'white',
    color: 'common.black',
    ':hover': {
      backgroundColor: 'common.white'
    },
    '& .MuiButton-startIcon': {
      left: 0,
      position: 'relative',
      '>*:first-of-type': {
        fontSize: '24px'
      }
    }
  }
};

const GameLinks: React.FunctionComponent<OptionalSxProps> = ({
  sx
}) => {
  const { t } = useTranslation();
  const [isActivated, setIsActivated] = useState(false);
  const [label, setLabel] = useState(t('game-links.label'));
  const { state } = useLocation();

  useEffect(() => {
    const changeLabelTimer = setTimeout(() => {
      if (isActivated) {
        setLabel(t('game-links.label'));
        setIsActivated(false);
      }
    }, 1200);

    return () => clearTimeout(changeLabelTimer);
  }, [isActivated]);

  const saveGameLink = useCallback(() => {
    setLabel(t('game-links.copied'));
    setIsActivated(true);
    const { courseId, username } = getAuth();
    const data = state
      ? `${getTokenById(state.levelId)}?nick=${username}`
      : courseId && `${getTokenById(courseId)}?nick=${username}`;

    const link = `${window.location.host}/${PAGE.GAME}/${data}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
    } else {
      handleLegacyClipboard(link);
    }
  }, [state]);

  return (
    <Button
      className="game-links"
      startIcon={<CopyIcon />}
      sx={mergeSxProps(styles.button, sx)}
      fullWidth
      onClick={saveGameLink}
    >
      { label }
    </Button>
  );
};

export default GameLinks;
