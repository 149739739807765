import { Box, InputLabel, TextField } from '@mui/material';

import { IStyles } from '../types/common';
import { FieldProps } from '../types/props';

const styles: IStyles = {
  container: {
    mb: 4
  },
  label: {
    mb: 2
  }
};

const Field: React.FunctionComponent<FieldProps> = ({
  error,
  field,
  helperText,
  label,
  required,
  type
}) => (
  <Box
    className="field"
    sx={styles.container}
  >
    <InputLabel
      className="field__label"
      required={required}
      sx={styles.label}
    >
      { label }
    </InputLabel>
    <TextField
      {...field}
      className="field__text-field"
      error={error !== undefined}
      helperText={error ? helperText[error.type] : ''}
      placeholder={label}
      required={required}
      type={type}
      variant="filled"
      fullWidth
    />
  </Box>
);

export default Field;
