import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Buttons from './Buttons.component';

import { PAGE } from '../pages/constants';
import { openModal } from '../redux/slices/modal.slice';
import RulesIcon from '../svgs/RulesIcon';
import WinnerIcon from '../svgs/WinnerIcon';
import { IStyles } from '../types/common';

const styles: IStyles = {
  button: {
    backgroundColor: 'secondary.main',
    color: 'primary.main',
    '&.MuiButton-contained:hover': {
      backgroundColor: 'secondary.main'
    }
  }
};

const HomeButtons: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRankingButton = useCallback(() => {
    navigate(PAGE.RANKING);
  }, []);

  const handleRulesButton = useCallback(() => {
    dispatch(openModal());
  }, []);

  return (
    <Buttons
      className="home__buttons"
      data={[
        {
          children: t('ranking.heading'),
          onClick: handleRankingButton,
          startIcon: <WinnerIcon />,
          sx: styles.button
        },
        {
          children: t('game-rules.heading'),
          onClick: handleRulesButton,
          startIcon: <RulesIcon />,
          sx: styles.button
        }
      ]}
    />
  );
};

export default HomeButtons;
