import { Button, Container, styled } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PAGE } from './constants';

import waveImage from '../assets/animations/wave.gif';
import Dialogue from '../components/Dialogue.component';
import Image from '../components/Image.component';
import Overlay from '../components/Overlay.component';
import { getAuth } from '../constants/common';
import { IStyles } from '../types/common';

const Username = styled('span')(({
  theme: { palette: { primary } }
}) => ({
  color: primary.main
}));

const styles: IStyles = {
  button: {
    ml: 'auto',
    maxWidth: 'fit-content'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    maxHeight: '-webkit-fill-available'
  },
  dialogue: {
    mb: 4,
    mt: 0
  },
  image: {
    alignSelf: 'end'
  }
};

const IntroductionPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Overlay className="introduction">
      <Container
        className="introduction__container"
        maxWidth="sm"
        sx={styles.container}
      >
        <Image
          alt={t('alt.wave')}
          className="introduction__hero"
          src={waveImage}
          sx={styles.image}
          width="334px"
        />
        <Dialogue
          className="introduction__dialogue"
          sx={styles.dialogue}
          hasArrow
        >
          <Trans
            components={{ Username: <Username /> }}
            i18nKey="introduction.hello"
            values={{ prop: getAuth().username }}
          />
        </Dialogue>
        <Button
          className="introduction__continue-button"
          sx={styles.button}
          onClick={() => navigate(`/${PAGE.DIFFICULTY}`)}
        >
          { t('button.continue') }
        </Button>
      </Container>
    </Overlay>
  );
};

export default IntroductionPage;
