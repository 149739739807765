import { RouteObject, createBrowserRouter } from 'react-router-dom';

import CongratulationsPage from './Congratulations.page';
import DifficultyPage from './Difficulty.page';
import Error404Page from './Error404.page';
import GamePage from './Game.page';
import HomePage from './Home.page';
import IntroductionPage from './Introduction.page';
import RankingPage from './Ranking.page';
import { PAGE } from './constants';

import MainLayout from '../layouts/Main.layout';
import ProtectedLayout from '../layouts/Protected.layout';

const protectedPages: RouteObject[] = [
  {
    element: <CongratulationsPage />,
    path: PAGE.CONGRATS
  },
  {
    element: <DifficultyPage />,
    path: PAGE.DIFFICULTY
  },
  {
    element: <GamePage />,
    path: PAGE.GAME
  },
  {
    element: <IntroductionPage />,
    path: PAGE.INTRODUCTION
  }
];

const publicPages: RouteObject[] = [
  {
    element: <HomePage />,
    path: PAGE.HOME
  },
  {
    element: <GamePage />,
    path: `${PAGE.GAME}/:slug`
  },
  {
    element: <RankingPage />,
    path: PAGE.RANKING
  }
];

export const router = createBrowserRouter([
  {
    children: publicPages,
    element: <MainLayout />
  },
  {
    children: protectedPages,
    element: <ProtectedLayout />
  },
  {
    element: <Error404Page />,
    path: PAGE.ERROR_404
  }
]);
