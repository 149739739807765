import { SvgIcon } from '@mui/material';

import { OptionalSxProps } from '../types/props';

const RulesIcon: React.FunctionComponent<OptionalSxProps> = (props) => (
  <SvgIcon
    height="34.045"
    viewBox="0 0 44.284 34.045"
    width="44.284"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(0 -59.158)">
      <g transform="translate(0 76.556)">
        <path
          d="M32.623,270.82H1.341A1.341,1.341,0,0,1,0,269.479l16.959-9.264,17.005,9.264A1.341,1.341,0,0,1,32.623,270.82Z"
          fill="#4793ff"
          fillRule="evenodd"
          transform="translate(0 -260.215)"
        />
      </g>
      <path
        d="M196.489,260.493v10.581h15.62a1.341,1.341,0,0,0,1.341-1.341Z"
        fill="#525cdd"
        fillRule="evenodd"
        transform="translate(-179.486 -183.913)"
      />
      <g transform="translate(0 59.158)">
        <path
          d="M.829,60.808c5.933-2.307,10.07-2.2,16.177.333l3.621,10.784L17.006,85.954c-6.387-2.644-10.62-2.645-17.006,0V62.019A1.3,1.3,0,0,1,.829,60.808Z"
          fill="#f4f3ff"
          fillRule="evenodd"
          transform="translate(0 -59.158)"
        />
      </g>
      <g transform="translate(17.003 59.158)">
        <path
          d="M212.666,60.808c-5.933-2.307-10.07-2.2-16.177.333V85.954c6.387-2.644,10.62-2.645,17.006,0V62.019A1.3,1.3,0,0,0,212.666,60.808Z"
          fill="#e2dff4"
          fillRule="evenodd"
          transform="translate(-196.489 -59.158)"
        />
      </g>
      <path
        d="M272.241,118.274l.067-.067a1.3,1.3,0,0,0-1.836-1.836l-.067.067-.067-.067a1.3,1.3,0,1,0-1.836,1.836l.067.067-.067.067a1.3,1.3,0,1,0,1.836,1.836l.067-.067.067.067a1.3,1.3,0,0,0,1.836-1.836Z"
        fill="#ff3b71"
        transform="translate(-244.922 -51.916)"
      />
      <g transform="translate(21.568 71.171)">
        <path
          d="M255.826,200.588h-5.279a1.3,1.3,0,1,1,0-2.6h5.279a1.3,1.3,0,1,1,0,2.6Z"
          fill="#00347b"
          transform="translate(-249.249 -197.992)"
        />
      </g>
      <g transform="translate(21.568 76.368)">
        <path
          d="M250.547,260.641a1.3,1.3,0,1,1,0-2.6h3.728Z"
          fill="#00347b"
          transform="translate(-249.249 -258.045)"
        />
      </g>
      <path
        d="M267.389,263.34c0-.011,0-.021,0-.032a5.519,5.519,0,0,0-5.52-5.264H249.774a5.519,5.519,0,0,0-5.52,5.264q0,.016,0,.032l-.006,8.291a3.252,3.252,0,0,0,3.248,3.249,3.4,3.4,0,0,0,3.137-2.359l1.03-2.507h8.319L261,272.494a3.288,3.288,0,0,0,3.129,2.385,3.255,3.255,0,0,0,2.313-.951,3.218,3.218,0,0,0,.953-2.3Z"
        fill="#bebcdd"
        transform="translate(-223.112 -181.676)"
      />
      <g transform="translate(26.074 80.372)">
        <path
          d="M304.669,305.21a1.3,1.3,0,1,0,.889,1.231A1.3,1.3,0,0,0,304.669,305.21Z"
          fill="#00347b"
          transform="translate(-301.318 -304.321)"
        />
        <path
          d="M409.923,306.441a4.763,4.763,0,1,0-.889,1.231A1.3,1.3,0,0,0,409.923,306.441Z"
          fill="#00347b"
          transform="translate(-396.652 -304.321)"
        />
      </g>
      <g transform="translate(6.21 64.076)">
        <path
          d="M74.1,120.556a1.3,1.3,0,0,1-.918-.38l-1.038-1.038a1.3,1.3,0,0,1,1.836-1.835l.12.12,1.051-1.051a1.3,1.3,0,1,1,1.836,1.836l-1.969,1.969A1.3,1.3,0,0,1,74.1,120.556Z"
          fill="#bee75e"
          transform="translate(-71.763 -115.991)"
        />
      </g>
      <g transform="translate(4.565 71.171)">
        <path
          d="M59.337,200.588H54.058a1.3,1.3,0,1,1,0-2.6h5.279a1.3,1.3,0,0,1,0,2.6Z"
          fill="#00429d"
          transform="translate(-52.76 -197.992)"
        />
      </g>
      <g transform="translate(4.565 76.363)">
        <path
          d="M59.337,260.587H54.058a1.3,1.3,0,1,1,0-2.6h5.279a1.3,1.3,0,1,1,0,2.6Z"
          fill="#00429d"
          transform="translate(-52.76 -257.991)"
        />
      </g>
    </g>
  </SvgIcon>
);

export default RulesIcon;
