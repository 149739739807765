import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Buttons from './Buttons.component';

import { DIFFICULTY } from '../constants/common';
import { PAGE } from '../pages/constants';
import { resetStats } from '../redux/slices/game.slice';
import { IStyles } from '../types/common';

const styles: IStyles = {
  button: {
    '&.buttons__button--0': {
      backgroundColor: 'success.light',
      ':hover': {
        backgroundColor: 'success.light'
      }
    },
    '&.buttons__button--1': {
      backgroundColor: 'warning.main',
      ':hover': {
        backgroundColor: 'warning.main'
      }
    },
    '&.buttons__button--2': {
      backgroundColor: 'error.main',
      ':hover': {
        backgroundColor: 'error.main'
      }
    }
  },
  buttons: {
    mt: 3
  }
};

const DifficultyButtons: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleButton = useCallback(({
    currentTarget: { value }
  }: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(resetStats());
    navigate(`/${PAGE.GAME}`, {
      state: {
        levelId: value
      }
    });
  }, []);

  return (
    <Buttons
      className="difficulty__buttons"
      data={[
        {
          children: t(`difficulty.level.${DIFFICULTY.EASY}`),
          onClick: handleButton,
          sx: styles.button,
          value: DIFFICULTY.EASY
        },
        {
          children: t(`difficulty.level.${DIFFICULTY.NORMAL}`),
          onClick: handleButton,
          sx: styles.button,
          value: DIFFICULTY.NORMAL
        },
        {
          children: t(`difficulty.level.${DIFFICULTY.HARD}`),
          onClick: handleButton,
          sx: styles.button,
          value: DIFFICULTY.HARD
        }
      ]}
      sx={styles.buttons}
    />
  );
};

export default DifficultyButtons;
