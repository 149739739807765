import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainLayout from './Main.layout';

import { getAuth } from '../constants/common';
import { PAGE } from '../pages/constants';

const ProtectedLayout: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);

  useEffect(() => {
    if (!getAuth()) {
      navigate(PAGE.HOME);
    } else {
      setIsAuthorizedUser(true);
    }
  }, []);

  return isAuthorizedUser ? <MainLayout /> : null;
};

export default ProtectedLayout;
