import { TypographyOptions } from '@mui/material/styles/createTypography';

export enum FONT_WEIGHT {
  BOLD = 700,
  EXTRABOLD = 800,
  MEDIUM = 500,
  REGULAR = 400,
  SEMIBOLD = 600
}

const typographyTheme: TypographyOptions = {
  body1: {
    fontSize: '16px',
    lineHeight: '25px'
  },
  body2: {
    fontSize: '10px',
    lineHeight: '16px'
  },
  button: {
    fontSize: '18px',
    fontWeight: FONT_WEIGHT.MEDIUM,
    lineHeight: '27px'
  },
  fontFamily: [
    'Poppins',
    'sans-serif'
  ].join(','),
  h1: {
    fontSize: '32px',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '50px'
  }
};

export default typographyTheme;
