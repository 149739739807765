import {
  Box, Button, Container, Typography
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PAGE } from './constants';

import RankingList from '../components/RankingList.component';
import { IStyles } from '../types/common';

const styles: IStyles = {
  container: {
    paddingBottom: 6,
    paddingTop: 4
  },
  heading: {
    color: 'common.white',
    mb: 4,
    textAlign: 'center'
  }
};

const RankingPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButton = useCallback(() => navigate(PAGE.HOME), []);

  return (
    <Box className="ranking">
      <Container
        className="ranking__container"
        maxWidth="sm"
        sx={styles.container}
      >
        <Typography
          className="ranking__heading"
          component="h2"
          sx={styles.heading}
          variant="h1"
        >
          { t('ranking.heading') }
        </Typography>
        <RankingList />
        <Button
          className="ranking__button"
          fullWidth
          onClick={handleButton}
        >
          { t('button.return-home') }
        </Button>
      </Container>
    </Box>
  );
};

export default RankingPage;
