import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Form from './Form.component';

import { STATUS_CODE } from '../api/constants';
import { initializeUser } from '../api/functions';
import { PAGE } from '../pages/constants';
import { setIsSavedGameError } from '../redux/slices/game.slice';
import { APIUserInitialization } from '../types/api';
import { IAuth, IStyles } from '../types/common';

const styles: IStyles = {
  form: {
    mb: 2
  }
};

const HomeForm: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmitButton = useCallback(({ username }: any) => {
    initializeUser(username).then(({
      data: { codes }
    }: AxiosResponse<APIUserInitialization>) => {
      const auth: IAuth = {
        username,
        tokens: {
          ...codes
        }
      };

      localStorage.setItem('auth', JSON.stringify(auth));
      navigate(PAGE.INTRODUCTION);
    })
      .catch(({ response }: AxiosError) => {
        if (response?.status === STATUS_CODE.ALREADY_TAKEN) {
          dispatch(setIsSavedGameError({
            isSavedGameError: {
              message: t('helperText.already-taken', {
                name: t('form.username')
              }),
              status: true
            }
          }));
        }
      });
  }, []);

  return (
    <Form
      button={{
        label: t('button.play'),
        onSubmit: handleSubmitButton
      }}
      fields={[
        {
          helperText: {
            minLength: t('helperText.too-short', {
              name: t('form.username')
            }),
            maxLength: t('helperText.too-long', {
              name: t('form.username')
            }),
            pattern: t('helperText.incorrect-data', {
              name: t('form.username')
            }),
            required: t('helperText.required')
          },
          label: t('form.username'),
          name: 'username',
          rules: {
            minLength: 2,
            maxLength: 16,
            pattern: {
              value: /^[a-zA-Z0-9_]+$/,
              message: t('helperText.incorrect-data', {
                name: t('form.username')
              })
            },
            required: true
          }
        }
      ]}
      sx={styles.form}
    />
  );
};

export default HomeForm;
