import { Box, Container } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import padImage from '../assets/hero/pad.png';
import CoFinancing from '../components/CoFinancing.component';
import HomeHeader from '../components/HomeHeader.component';
import HomeMainContent from '../components/HomeMainContent.component';
import { setModal } from '../redux/slices/modal.slice';
import { IStyles } from '../types/common';

const styles: IStyles = {
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between'
  }
};

const HomePage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setModal({
      content: {
        data: t('game-rules.content') as string,
        image: {
          alt: t('alt.hero-page'),
          src: padImage
        },
        type: 'default'
      },
      closeOption: true,
      title: t('game-rules.heading')
    }));
  }, []);

  return (
    <Box
      className="home"
      sx={styles.root}
    >
      <Container
        className="home__container"
        maxWidth="sm"
        sx={styles.content}
      >
        <HomeHeader />
        <HomeMainContent />
      </Container>
      <CoFinancing className="home__co-financing" />
    </Box>
  );
};

export default HomePage;
