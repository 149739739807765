import { Outlet } from 'react-router-dom';

import Modal from '../components/Modal.component';

const MainLayout: React.FunctionComponent = () => (
  <>
    <Modal />
    <Outlet />
  </>
);

export default MainLayout;
