import {
  Checkbox as MUICheckbox,
  FormControlLabel as MUIFormControlLabel,
  styled
} from '@mui/material';

import { IStyles } from '../types/common';
import { DecisionItemProps } from '../types/props';

type CheckboxProps = {
  correct: boolean;
};

const Checkbox = styled(MUICheckbox)<CheckboxProps>(({
  correct, theme: { palette: { error, success } }
}) => ({
  marginLeft: 0,
  marginRight: 16,
  padding: 0,
  '&.Mui-disabled.Mui-checked': {
    color: correct ? success.main : error.main
  },
  '& .MuiSvgIcon-root': {
    height: '40px',
    width: '40px'
  }
}));

const FormControlLabel = styled(MUIFormControlLabel)<CheckboxProps>(({
  correct, theme: { palette: { error, success } }
}) => ({
  '&.Mui-checked .Mui-disabled.MuiFormControlLabel-label': {
    color: correct ? success.main : error.main
  }
}));

const styles: IStyles = {
  formControl: {
    ':last-of-type': {
      mb: '44px'
    }
  }
};

const DecisionBlock: React.FunctionComponent<DecisionItemProps> = ({
  checked, disabled, isCorrect, name, selectCheckbox
}) => (
  <FormControlLabel
    className={checked ? 'Mui-checked' : undefined}
    control={(
      <Checkbox
        checked={checked}
        correct={isCorrect}
        disabled={disabled}
        name={name}
        onChange={({ target }) => {
          selectCheckbox(target.name, isCorrect);
        }}
      />
      )}
    correct={isCorrect}
    label={name}
    sx={styles.formControl}
  />
);

export default DecisionBlock;
