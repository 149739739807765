import { List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RankingItem from './RankingItem.component';

import { getRankingItems } from '../api/functions';
import { APIUser } from '../types/api';
import { IStyles } from '../types/common';

const styles: IStyles = {
  error: {
    color: 'common.white',
    mb: 5,
    textAlign: 'center'
  },
  list: {
    mb: '20px'
  }
};

const RankingList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<APIUser[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await getRankingItems();
      setUsers(data);
    };

    fetchUsers();
  }, []);

  return users.length > 0 ? (
    <List
      className="ranking__list"
      sx={styles.list}
      disablePadding
    >
      {
        users.map(
          (props, index) => (
            <RankingItem
              {...props}
              index={index}
              key={`Ranking-list-item-${props.nick}-${index}`}
            />
          )
        )
      }
    </List>
  ) : (
    <Typography sx={styles.error}>
      { t('ranking.error') }
    </Typography>
  );
};

export default RankingList;
