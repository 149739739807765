import { Box } from '@mui/material';

import { mergeSxProps } from '../constants/common';
import { IStyles } from '../types/common';
import { ImageProps } from '../types/props';

const styles: IStyles = {
  container: {
    '& img': {
      display: 'block',
      width: '100%'
    }
  }
};

const Image: React.FunctionComponent<ImageProps> = ({
  alt, className, src, sx, width
}) => (
  <Box
    className={className}
    sx={
      mergeSxProps({
        maxWidth: width,
        ...styles.container
      }, sx)
    }
  >
    <img
      alt={alt}
      src={src}
    />
  </Box>
);

export default Image;
