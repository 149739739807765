import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Dialogue from './Dialogue.component';
import Image from './Image.component';

import introImage from '../assets/hero/intro.png';
import { convertToHoursMinutesSeconds } from '../constants/ranking';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';
import { RXStates } from '../types/redux';

const styles: IStyles = {
  dialogue: {
    mb: 4,
    ml: 'auto',
    maxWidth: '90%'
  },
  image: {
    mx: 'auto',
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '280px',
      transform: 'scale(1.2)'
    }
  }
};

const CongratulationsHero: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { points } = useSelector(({ game }: RXStates) => game);

  return (
    <>
      <Image
        alt=""
        className="congratulations__hero"
        src={introImage}
        sx={styles.image}
        width="440px"
      />
      <Dialogue
        className="congratulations__dialogue"
        sx={styles.dialogue}
      >
        { t(`congratulations.${state.levelId}`, {
          points: `${points} pkt.`,
          time: convertToHoursMinutesSeconds(state.time)
        }) }
      </Dialogue>
    </>
  );
};

export default CongratulationsHero;
