import { SxProps } from '@mui/material';

import { IAuth } from '../types/common';

export enum DIFFICULTY {
  EASY = 1,
  HARD = 3,
  NORMAL = 2
}

export enum LOCAL_STORAGE {
  AUTH = 'auth',
  USERNAME = 'username'
}

export const getAuth = (): IAuth => {
  const auth = localStorage.getItem(LOCAL_STORAGE.AUTH);

  return auth ? JSON.parse(auth) : null;
};

export const getTokenById = (levelId: string) => {
  const { tokens } = getAuth();

  return tokens[levelId];
};

export const getUsername = () => {
  const username = localStorage.getItem(LOCAL_STORAGE.USERNAME);

  return username ? JSON.parse(username) : null;
};

export const mergeSxProps = (primary: SxProps, secondary?: SxProps) => (
  [primary, ...(Array.isArray(secondary) ? secondary : [secondary])]
);

export const updateLocalStorage = (key: string, data: string) => {
  localStorage.setItem(key, JSON.stringify(data));
};
