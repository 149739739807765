import { Paper } from '@mui/material';
import { PropsWithChildren } from 'react';

import overlayImage from '../assets/overlay.svg';
import { theme } from '../theme/settings';
import { IStyles } from '../types/common';
import { NestedComponentProps } from '../types/props';

const styles: IStyles = {
  container: {
    backgroundColor: 'transparent',
    backgroundImage: `url(${overlayImage})`,
    backgroundPositionX: '50%',
    backgroundPositionY: '80%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 0,
    [theme.breakpoints.up('xl')]: {
      backgroundPositionY: '60%'
    }
  }
};

const Overlay: React.FunctionComponent<PropsWithChildren<NestedComponentProps>> = ({
  className, children
}) => (
  <Paper
    className={className}
    elevation={0}
    sx={styles.container}
  >
    { children }
  </Paper>
);

export default Overlay;
